import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Slider from "react-slick";
import styled from 'styled-components';
import UserPlaceholder from "../../assets/images/user-placeholder-small.png";
import StarRating from "../../components/TuorPanel/Accordion/starRatings";
import { hostConfig } from "../../config";
import { getallreviewsData } from "../../redux/action/reviewAction";

// Styled Components
const ReviewSection = styled.section`
  padding: 60px 0;
  background: #f8f9fa;
`;

const ReviewTitle = styled.h2`
  font-size: 32px;
  font-weight: 600;
  color: #002147;
  text-align: center;
  margin-bottom: 40px;
`;

const StudentsCard = styled.div`
  padding: 10px;
  height: 100%;
`;

const StudentsCardWrap = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.08);
  height: 300px;
  display: flex;
  flex-direction: column;
`;

const StudentsName = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

const StudentsImg = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const StudentsReview = styled.div`
  h6 {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
    font-weight: normal;
  }

  h4 {
    font-size: 18px;
    color: #171717;
    font-weight: 600;
    margin: 0;
  }
`;

const StudentsDetails = styled.p`
  font-size: 15px;
  line-height: 1.6;
  color: #666;
  flex: 1;
  overflow-y: auto;
  margin: 20px 0;
`;

const StudentsStar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eee;
  padding-top: 20px;
  margin-top: auto;

  span {
    font-size: 14px;
    color: #666;
  }

  ul {
    display: flex;
    gap: 5px;
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

const ReviewCard = styled.div`
  .slick-track {
    display: flex !important;
    align-items: stretch;
  }

  .slick-slide {
    height: inherit !important;
    > div {
      margin: 0 10px;
      height: 100%;
    }
  }

  .slick-list {
    margin: 0 -10px;
  }

  .slick-prev,
  .slick-next {
    width: 40px;
    height: 40px;
    z-index: 1;
    
    &:before {
      color: #002147;
      font-size: 40px;
    }
  }

  .slick-prev {
    left: -45px;
  }

  .slick-next {
    right: -45px;
  }
`;

const ReviewSectionCard = () => {
  const dispatch = useDispatch();
  const [reviewData, setReviewData] = useState([]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getAllReviews = async () => {
    try {
      const res = await dispatch(getallreviewsData());
      if (res?.records) {
        setReviewData(res.records);
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    getAllReviews();
  }, []);

  return (
    <ReviewSection>
      <Container>
        <Row>
          <Col xs={12} sm={12}>
            <ReviewTitle>Recent Reviews From Students</ReviewTitle>
          </Col>
          <Col sm={12}>
            <ReviewCard>
              <Slider {...settings}>
                {reviewData?.map((item) => (
                  <StudentsCard key={item.id}>
                    <StudentsCardWrap>
                      <StudentsName>
                        <StudentsImg>
                          <img
                            src={
                              item?.student_details?.is_profile
                                ? `${hostConfig.S3_BUCKET}${item?.student_details?.profile_name}`
                                : UserPlaceholder
                            }
                            alt={item?.student_details?.student_first_name}
                          />
                        </StudentsImg>
                        <StudentsReview>
                          <h6>
                            Reviewed for {item?.teacher_details?.tuition_name}
                          </h6>
                          <h4>{`${item?.student_details?.student_first_name} ${item?.student_details?.student_last_name}`}</h4>
                        </StudentsReview>
                      </StudentsName>
                      <StudentsDetails>
                        {item?.review?.reviewed_data}
                      </StudentsDetails>
                      <StudentsStar>
                        <span>
                          {moment(
                            item?.review?.created_at,
                            "YYYY-MM-DDTHH:mm:ss.SSSSSS"
                          ).format("DD-MMM-YYYY")}
                        </span>
                        <StarRating
                          rating={item.review.start_count}
                          totalStars={5}
                          starSize={28}
                        />
                      </StudentsStar>
                    </StudentsCardWrap>
                  </StudentsCard>
                ))}
              </Slider>
            </ReviewCard>
          </Col>
        </Row>
      </Container>
    </ReviewSection>
  );
};

export default ReviewSectionCard;