import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { useState } from "react";
import { Row, Col } from 'antd';
import "./piechartStyle.css"; // Create a CSS file for custom styles

const PieChart = ({ data, title }) => {
  const [tooltip, setTooltip] = useState(null);


  if (!data || !Object.keys(data).length || Object.values(data).every(value => value === 0)) {
      return (
        <div className="card pie-chart-card p-3 h-100">
          <strong className="mb-0 text-center my-3 mb-3">{title}</strong>
          <div className="d-flex justify-content-center">
            <svg width={80} height={80}>
              <circle
                cx={40}
                cy={40}
                r={35}
                fill="#f5f5f5"
                stroke="#e0e0e0"
                strokeWidth="2"
              />
              <text
                x="40"
                y="44"
                textAnchor="middle"
                fill="#999"
                fontSize="12px"
                fontFamily="Arial"
              >
                No Data
              </text>
            </svg>
          </div>
          <Row gutter={[8, 8]} className="mt-3 d-flex flex-wrap justify-content-center">
            <Col className="text-center text-muted">
              <p className="small m-0">No data available</p>
            </Col>
          </Row>
        </div>
      );
  }
  // Define an array of colors
  const colors = [
    "#012834",
    "#74c5d7",
    "#940e34",
    "#707070",
    "#f17a9b",
    "#b9b9b9",
  ];

  // Function to generate a random color from the array
  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const calculateSliceAngles = (data) => {
    const total = Object.values(data).reduce((acc, value) => acc + value, 0);
  
    let startAngle = 0;
    return Object.entries(data).map(([label, value]) => {
      const angle = (value / total) * 360;
      const endAngle = startAngle + angle;
      const slice = {
        label,
        value,
        startAngle,
        endAngle,
        color: getRandomColor(), // Get a random color for the slice
      };
      startAngle = endAngle;
      return slice;
    });
  };

  const describeArc = (x, y, radius, startAngle, endAngle) => {
    const start = polarToCartesian(x, y, radius, endAngle);
    const end = polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    return `M ${start.x} ${start.y} A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y} L ${x} ${y} Z`;
  };

  const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
    const angleInRadians = (angleInDegrees - 90) * (Math.PI / 180);
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const slices = calculateSliceAngles(data);
  const centerX = 40; // Adjust as needed
  const centerY = 40; // Adjust as needed
  const radius = 40; // Adjust as needed

  const handleSliceHover = (slice, event) => {
    setTooltip({ slice, x: event.clientX, y: event.clientY });
  };

  const handleSliceLeave = () => {
    setTooltip(null);
  };

  return (
    <div className="card pie-chart-card p-3 h-100">
      <strong className="mb-0 text-center my-3 mb-3">{title}</strong>
      <div className="d-flex justify-content-center">
        <svg width={2 * centerX} height={2 * centerY}>
          {slices?.map((slice, index) => (
            <g
              key={index}
              onMouseEnter={(event) => handleSliceHover(slice, event)}
              onMouseLeave={handleSliceLeave}
            >
              <path
                d={describeArc(
                  centerX,
                  centerY,
                  radius,
                  slice.startAngle,
                  slice.endAngle
                )}
                fill={slice.color}
                stroke="#fff" // Add a border
                strokeWidth="2" // Adjust border width as needed
              />
            </g>
          ))}
        </svg>
      </div>
      <Row gutter={[8, 8]} className="mt-3 d-flex flex-wrap justify-content-between">
        {slices?.map((slice, index) => (
          <Col key={index} className="d-flex align-items-center justify-content-center">
            <div
              style={{
                backgroundColor: slice.color,
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></div>
            <p className="ms-1 small m-0">{`${slice.label}`}</p>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default PieChart;
