import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components';
import Header from '../../components/Header';
import Footer from "../../components/Footer";

const PageContainer = styled.div`
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f8f9fa;
  color: #333;
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const FeatureWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 20px;
`;

const SectionTitle = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  color: #940E34;  
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled.h2`
  text-align: center;
  font-size: 1.5rem;
  color: #940E34;
  font-weight: 600;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const RegisterButton = styled.button`
  background: #940E34;
  color: white;
  border: none;
  padding: 12px 28px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(148, 14, 52, 0.2);

  &:hover {
    background: #7b0c2b;
    transform: translateY(-2px);
    box-shadow: 0 6px 12px rgba(148, 14, 52, 0.3);
  }
`;

const ButtonWrapper = styled.div`
  text-align: center;
  margin: 30px 0;
`;

const BenefitsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 800px;
  margin: 0 auto 50px auto;
`;

const BenefitCard = styled.div`
  color: #940E34;
  font-weight: 600;
  padding: 24px 32px;
  border-radius: 12px;
  width: 100%;
  font-size: 1.2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background: white;

  &:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
`;

const Divider = styled.hr`
  border: none;
  border-top: 2px solid #ddd;
  margin: 40px 0;
`;

const ProcessSection = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 20px 0;
  }
`;

const StepItem = styled.div`
  display: flex;
  justify-content: ${props => props.$isEven ? 'flex-start' : 'flex-end'};
  position: relative;
  width: 100%;
  min-height: 100px;
  padding: ${props => props.$isEven ? '0 0 0 80px' : '0 80px 0 0'};

  @media (max-width: 768px) {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
`;

const StepContent = styled.div`
  width: 500px;
  background: #f8f9fa;
  border-radius: 30px;
  position: relative;
  box-shadow: 20px 20px 50px #d1d1d1, -20px -20px 50px #ffffff;
  padding: 25px 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 25px 25px 60px #c8c8c8, -25px -25px 60px #ffffff;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: 500px;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    text-align: center;
  }
`;

const StepNumber = styled.div`
  background-color: #940E34;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  ${props => props.$isEven ? 'left: 0;' : 'right: 0;'}
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    position: static;
    transform: none;
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
  }
`;

const StepIcon = styled.div`
  font-size: 1.8rem;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  ${StepContent}:hover & {
    transform: scale(1.2);
    color: #940E34;
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    min-width: 30px;
  }
`;

const StepInfo = styled.div`
  flex: 1;
`;

const StepTitle = styled.h3`
  color: #940E34;
  font-weight: 600;
  margin: 0;
  font-size: 1.3rem;
  transition: transform 0.3s ease;

  ${StepContent}:hover & {
    transform: translateX(5px);
  }

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const StepDescription = styled.p`
  margin: 2px 0 0;
  font-size: 1.1rem;
  color: #4a5568;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
`;

const NeumorphicSection = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  background: #f8f9fa;
`;

const NeumorphicContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  margin-top: 40px;
  padding: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 30px;
  }
`;

const InfoCard = styled.div`
  background: #f8f9fa;
  border-radius: 24px;
  padding: 40px 30px;
  position: relative;
  box-shadow: 20px 20px 60px #d1d1d1, 
              -20px -20px 60px #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 25px 25px 75px #d1d1d1, 
                -25px -25px 75px #ffffff;
  }
`;

const InfoCircleWrapper = styled.div`
  width: 120px;
  height: 120px;
  position: relative;
  margin-bottom: 25px;
  border-radius: 50%;
  background: #f8f9fa;
  box-shadow: inset 8px 8px 16px #d1d1d1,
              inset -8px -8px 16px #ffffff;
  padding: 4px;
  transition: all 0.3s ease;

  ${InfoCard}:hover & {
    transform: scale(1.05);
    box-shadow: inset 10px 10px 20px #d1d1d1,
                inset -10px -10px 20px #ffffff;
  }
`;

const InfoCircleBorder = styled.div`
  position: absolute;
  inset: 4px;
  border-radius: 50%;
  background: linear-gradient(145deg, #940E34, #ff6b6b);
  opacity: 0.8;
  animation: rotate 4s linear infinite;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  ${InfoCard}:hover & {
    animation-duration: 3s;
  }
`;

const InfoCircle = styled.div`
  width: 100%;
  height: 100%;
  background: #f8f9fa;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.5rem;
  position: relative;
  z-index: 1;
  box-shadow: 5px 5px 10px #d1d1d1,
              -5px -5px 10px #ffffff;
  transition: all 0.3s ease;

  ${InfoCard}:hover & {
    transform: scale(1.1);
  }
`;

const InfoTitle = styled.h3`
  color: #940E34;
  font-size: 1.4rem;
  margin: 15px 0;
  font-weight: 600;
  transition: all 0.3s ease;

  ${InfoCard}:hover & {
    transform: scale(1.05);
  }
`;

const InfoDescription = styled.p`
  font-size: 1.1rem;
  line-height: 1.7;
  color: #4a5568;
  font-weight: 500;
  margin: 0;
  transition: all 0.3s ease;

  ${InfoCard}:hover & {
    color: #444;
  }
`;

const HelpSection = styled.div`
  border-radius: 16px;
  margin-top: 40px;
  padding: 40px;
  box-shadow: 0 8px 32px rgba(153, 0, 51, 0.1);
`;

const HelpSectionTitle = styled(SectionTitle)`
  font-size: 2rem;
  color: #940E34;
  margin-bottom: 16px;
  
  @media (max-width: 768px) {
    font-size: 1.6rem;
  }
`;

const HelpSectionContent = styled.p`
  color: #940E34;
  font-size: 1.2rem;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const EmailLink = styled.a`
  color: #940E34;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1rem;
  padding: 2px 8px;
  border-radius: 4px;
  transition: all 0.3s ease;
  border-bottom: 2px solid transparent;

  &:hover {
    border-bottom-color: #ffcccb;
    background: rgba(255, 255, 255, 0.1);
  }
`;

export default function Teachers() {
  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate('/auth/register?tab=teacher');
  };

  const benefits = [
    "Access to millions of students on the Platform.",
    "Seamless student data management.",
    "Time saving by over 50% in managing student data.",
    "Cost savings in advertisements."
  ];

  const steps = [
    {
      number: "1",
      title: "Search Tuition",
      description: "Tuition Mitra Platform shows relevant tuition options.",
      icon: "🔍"
    },
    {
      number: "2",
      title: "Select Teacher",
      description: "Student chooses the best fit.",
      icon: "👩‍🏫"
    },
    {
      number: "3",
      title: "Initial Payment",
      description: "Student pays Rs. 200 to enroll.",
      icon: "💳"
    },
    {
      number: "4",
      title: "Visit Institute",
      description: "Student visits the tuition place.",
      icon: "🏫"
    },
    {
      number: "5",
      title: "Pay Balance",
      description: "Student pays remaining fees directly.",
      icon: "💰"
    }
  ];

  const infoData = [
    {
      icon: "📋",
      title: "Register as a Teacher",
      description: "Teacher initiates registration on the platform."
    },
    {
      icon: "📚",
      title: "Update Batch Information",
      description: "Teacher updates current or existing batch details."
    },
    {
      icon: "📸",
      title: "Update Profile",
      description: "Teacher uploads image and sample videos."
    }
  ];

  return (
    <>
      <Header />
      <PageContainer>
        <FeatureWrapper>
          <SectionTitle>Teachers, we are excited to have you here!!</SectionTitle>
          <Subtitle>Why teachers should join us?</Subtitle>
          <BenefitsContainer>
            {benefits.map((benefit, index) => (
              <BenefitCard key={index}>{benefit}</BenefitCard>
            ))}
          </BenefitsContainer>
          <ButtonWrapper>
            <RegisterButton onClick={handleRegisterClick}>
              Register as Teacher
            </RegisterButton>
          </ButtonWrapper>
        </FeatureWrapper>

        <Divider />

        <ProcessSection>
          <SectionTitle>How does TuitionMitra work for Teachers?</SectionTitle>
          <Subtitle>Student Easy Enrollment Process</Subtitle>
          <StepsContainer>
            {steps.map((step, index) => (
              <StepItem key={step.number} $isEven={index % 2 !== 0}>
                <StepNumber $isEven={index % 2 !== 0}>
                  {step.number}
                </StepNumber>
                <StepContent>
                  <StepIcon>{step.icon}</StepIcon>
                  <StepInfo>
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </StepInfo>
                </StepContent>
              </StepItem>
            ))}
          </StepsContainer>
          <ButtonWrapper>
            <RegisterButton onClick={handleRegisterClick}>
              Register Now
            </RegisterButton>
          </ButtonWrapper>
        </ProcessSection>

        <Divider />

        <NeumorphicSection>
          <SectionTitle>How can Teachers register on the website?</SectionTitle>
          <Subtitle>Teacher Registration and Profile Update Process</Subtitle>
          <NeumorphicContainer>
            {infoData.map((info, index) => (
              <InfoCard key={index}>
                <InfoCircleWrapper>
                  <InfoCircleBorder />
                  <InfoCircle>{info.icon}</InfoCircle>
                </InfoCircleWrapper>
                <InfoTitle>{info.title}</InfoTitle>
                <InfoDescription>{info.description}</InfoDescription>
              </InfoCard>
            ))}
          </NeumorphicContainer>
          <ButtonWrapper>
            <RegisterButton onClick={handleRegisterClick}>
              Register Now
            </RegisterButton>
          </ButtonWrapper>
        </NeumorphicSection>

        <HelpSection>
          <HelpSectionTitle>Help and Support for Teachers</HelpSectionTitle>
          <HelpSectionContent>
            Comprehensive help and support are available for teachers to enhance their teaching experience and address any challenges. Just write to:{' '}
            <EmailLink href="mailto:help@tuitionmitra.com">
              help@tuitionmitra.com
            </EmailLink>
          </HelpSectionContent>
        </HelpSection>
      </PageContainer>
      <Footer />
    </>
  );
}