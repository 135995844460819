import React, { useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import { Alert } from 'antd';
import { SyncOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { CreateTeacherOTP, CreateOTP, ResetTeacherPassword, ResetPassword } from "../../../redux/action/otpAction";
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Container = styled.div`
  width: 500px;
  margin: 40px auto;
  background: #fff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  
  @media (max-width: 600px) {
    width: 90%;
    margin: 20px auto;
  }
`;

const Title = styled.h2`
  color: #012834;
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: 600;
  text-transform: capitalize;
`;

const Description = styled.p`
  color: #666;
  margin-bottom: 25px;
  font-size: 14px;
`;

const FormGroup = styled(Form.Group)`
  margin-bottom: 20px;

  .form-label {
    font-size: 16px;
    color: #012834;
    margin-bottom: 8px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .form-control {
    background: #ffffff;
    color: #012834;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    font-size: 14px;

    &:focus {
      border-color: #940e34;
      box-shadow: 0 0 0 0.2rem rgba(148, 14, 52, 0.1);
    }

    &:disabled {
      background: #f8f9fa;
    }
  }
`;

const SubmitButton = styled(Button)`
  width: 180px;
  font-weight: 500;
  background: #940e34;
  border-color: #940e34;
  padding: 10px 24px;
  border-radius: 25px;
  display: block;
  margin: 30px auto 0;
  white-space: nowrap;
  transition: all 0.3s ease;

  &:hover, &:focus {
    background: #7a0c2b;
    border-color: #7a0c2b;
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(148, 14, 52, 0.2);
  }

  &:disabled {
    background: #6c757d;
    border-color: #6c757d;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }
`;

const ErrorText = styled.div`
  color: #dc3545;
  font-size: 12px;
  margin-top: 4px;
`;

const BackButton = styled.a`
  color: #940e34;
  font-size: 14px;
  margin-top: 20px;
  display: block;
  text-align: center;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const InputGroupText = styled(InputGroup.Text)`
  cursor: pointer;
  &:hover {
    color: #495057;
  }
`;

const ForgotPassword: React.FC = () => {
  const formikRef = useRef<any>(null);
  const [isLoading, setLoading] = useState(false);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [apiError, setApiError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { userType } = useParams();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const handleBack = () => {
    setShowOtpForm(false);
    setApiError("");
    setEmailValue("");
    if (formikRef.current) {
      formikRef.current.resetForm();
    }
  };

  const handleEmailSubmit = (values: { email: string }) => {
    setLoading(true);
    setApiError("");
    
    const payload = { email_id: values.email };
    const action = userType === 'teacher' ? CreateTeacherOTP : CreateOTP;

    dispatch(action(payload))
      .then((res: any) => {
        if (res?.success) {
          setEmailValue(values.email);
          setShowOtpForm(true);
        } else {
          setApiError(res?.detail?.error || "Failed to send OTP");
        }
      })
      .catch(() => {
        setApiError("Something went wrong. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleResetSubmit = (values: any) => {
    setLoading(true);
    setApiError("");
    
    const payload = {
      email_id: emailValue,
      otp: values.otp,
      new_password: values.newPassword
    };

    const action = userType === 'teacher' ? ResetTeacherPassword : ResetPassword;

    dispatch(action(payload))
      .then((res: any) => {
        if (res?.success) {
          setIsSuccess(true);
          setTimeout(() => {
            navigate('/auth/login');
          }, 2000);
        } else {
          setApiError(res?.detail?.error || "Failed to reset password");
        }
      })
      .catch(() => {
        setApiError("Something went wrong. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (isSuccess) {
    return (
      <Container>
        <Title>Password Updated</Title>
        <Alert
          style={{ margin: "20px 0" }}
          message="Password updated successfully! Redirecting to login..."
          type="success"
          showIcon
        />
      </Container>
    );
  }

  return (
    <Container>
      <Title>Forgot Password</Title>
      {!showOtpForm ? (
        <>
          <Description>
            Enter your email address and we'll send you an OTP to reset your password.
          </Description>

          <Formik
            initialValues={{ email: '' }}
            validate={(values) => {
              const errors: any = {};
              if (!values.email) {
                errors.email = "Please enter your Email ID";
              } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = "Invalid email address";
              }
              return errors;
            }}
            onSubmit={handleEmailSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter your email address"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.email && errors.email && (
                    <ErrorText>{errors.email}</ErrorText>
                  )}
                </FormGroup>

                {apiError && <ErrorText>{apiError}</ErrorText>}

                <SubmitButton
                  type="submit"
                  disabled={!values.email || isLoading}
                >
                  {isLoading ? <SyncOutlined spin /> : "Send OTP"}
                </SubmitButton>
              </form>
            )}
          </Formik>
        </>
      ) : (
        <>
          <Description>
            Enter the OTP sent to your email and set your new password.
          </Description>

          <Formik
            innerRef={formikRef}
            initialValues={{
              otp: '',
              newPassword: '',
              confirmPassword: ''
            }}
            validate={(values) => {
              const errors: any = {};
              if (!values.otp) {
                errors.otp = "OTP is required";
              } else if (values.otp.length !== 4) {
                errors.otp = "OTP must be 4 characters";
              }
              if (!values.newPassword) {
                errors.newPassword = "New password is required";
              }
              if (!values.confirmPassword) {
                errors.confirmPassword = "Please confirm your password";
              } else if (values.newPassword !== values.confirmPassword) {
                errors.confirmPassword = "Passwords do not match";
              }
              return errors;
            }}
            onSubmit={handleResetSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <FormGroup>
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    type="email"
                    value={emailValue}
                    disabled
                  />
                </FormGroup>

                <FormGroup>
                  <Form.Label>OTP</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter OTP"
                    name="otp"
                    value={values.otp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    maxLength={4}
                  />
                  {touched.otp && errors.otp && (
                    <ErrorText>{errors.otp}</ErrorText>
                  )}
                </FormGroup>

                <FormGroup>
                  <Form.Label>New Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter new password"
                      name="newPassword"
                      value={values.newPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <InputGroupText onClick={() => setShowPassword(!showPassword)}>
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </InputGroupText>
                  </InputGroup>
                  {touched.newPassword && errors.newPassword && (
                    <ErrorText>{errors.newPassword}</ErrorText>
                  )}
                </FormGroup>

                <FormGroup>
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirm new password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <InputGroupText onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </InputGroupText>
                  </InputGroup>
                  {touched.confirmPassword && errors.confirmPassword && (
                    <ErrorText>{errors.confirmPassword}</ErrorText>
                  )}
                </FormGroup>

                {apiError && <ErrorText>{apiError}</ErrorText>}

                <SubmitButton
                  type="submit"
                  disabled={isLoading || !values.otp || !values.newPassword || 
                           !values.confirmPassword || values.otp.length !== 4}
                >
                  {isLoading ? <SyncOutlined spin /> : "Update Password"}
                </SubmitButton>

                <BackButton onClick={handleBack}>
                  ← Back to Email Entry
                </BackButton>
              </form>
            )}
          </Formik>
        </>
      )}
    </Container>
  );
};

export default ForgotPassword;