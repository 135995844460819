/** **************************** Import Types ****************************** */
import "react-toastify/dist/ReactToastify.css";
import { OTP_FAILURE, OTP_REQUEST, OTP_SUCCESS } from "../types/otpType";
import {
  postOTPData,
  validateOTPData,
  postPasswordData,
  postTeacherOTPData,
  validateTeacherOTPData,
  postTeacherPasswordData,
} from "../../api/create";

export const OTPRequest = () => ({
  type: OTP_REQUEST,
});

export const OTPSuccess = (users) => ({
  type: OTP_SUCCESS,
  payload: users,
});

export const OTPFailure = (error) => ({
  type: OTP_FAILURE,
  payload: error,
});

export const CreateOTP = (data) =>
  async function (dispatch) {
    dispatch(OTPRequest());
    try {
      const response = await postOTPData(data);
      if (!response) {
        const errorMessage = {
          message: "Sudent user email ID not found",
          success: false,
        };
        dispatch(
          OTPFailure({ responseStatus: "failure", error: errorMessage })
        );
        return errorMessage;
      } else {
        dispatch(OTPSuccess({ responseStatus: "success" }));
        return response;
      }
    } catch (error) {
      console.error("OTP creation error:", error);
      const errorMessage = "OTP creation failed";
      dispatch(OTPFailure({ responseStatus: "failure", error: errorMessage }));
      return errorMessage;
    }
  };

  export const ValidateOTP = (data) =>
  async function (dispatch) {
    dispatch(OTPRequest());
    try {
      const response = await validateOTPData(data);
      if (!response) {
        const errorMessage = {
          message: "Sudent user email ID not found",
          success: false,
        };
        dispatch(
          OTPFailure({ responseStatus: "failure", error: errorMessage })
        );
        return errorMessage;
      } else {
        dispatch(OTPSuccess({ responseStatus: "success" }));
        return response;
      }
    } catch (error) {
      console.error("OTP creation error:", error);
      const errorMessage = "OTP Invalid";
      dispatch(OTPFailure({ responseStatus: "failure", error: errorMessage }));
      return errorMessage;
    }
  };
export const CreateTeacherOTP = (data) =>
  async function (dispatch) {
    dispatch(OTPRequest());
    try {
      const response = await postTeacherOTPData(data);
      if (!response) {
        const errorMessage = {
          message: "Sudent user email ID not found",
          success: false,
        };
        dispatch(
          OTPFailure({ responseStatus: "failure", error: errorMessage })
        );
        return errorMessage;
      } else {
        dispatch(OTPSuccess({ responseStatus: "success" }));
        return response;
      }
    } catch (error) {
      console.error("OTP creation error:", error);
      const errorMessage = "OTP creation failed";
      dispatch(OTPFailure({ responseStatus: "failure", error: errorMessage }));
      return errorMessage;
    }
  };
  export const ValidatedTeacherOTP = (data) =>
  async function (dispatch) {
    dispatch(OTPRequest());
    try {
      const response = await validateTeacherOTPData(data);
      if (!response) {
        const errorMessage = {
          message: "Sudent user email ID not found",
          success: false,
        };
        dispatch(
          OTPFailure({ responseStatus: "failure", error: errorMessage })
        );
        return errorMessage;
      } else {
        dispatch(OTPSuccess({ responseStatus: "success" }));
        return response;
      }
    } catch (error) {
      console.error("OTP creation error:", error);
      const errorMessage = "OTP invalid";
      dispatch(OTPFailure({ responseStatus: "failure", error: errorMessage }));
      return errorMessage;
    }
  };



  export const ResetPassword = (data) =>
  async function (dispatch) {
    dispatch(OTPRequest());
    try {
      const response = await postPasswordData(data);
      if (!response) {
        const errorMessage = {
          message: "Sudent user email ID not found",
          success: false,
        };
        dispatch(
          OTPFailure({ responseStatus: "failure", error: errorMessage })
        );
        return errorMessage;
      } else {
        dispatch(OTPSuccess({ responseStatus: "success" }));
        return response;
      }
    } catch (error) {
      console.error("OTP creation error:", error);
      const errorMessage = "OTP creation failed";
      dispatch(OTPFailure({ responseStatus: "failure", error: errorMessage }));
      return errorMessage;
    }
  };


  export const ResetTeacherPassword = (data) =>
  async function (dispatch) {
    dispatch(OTPRequest());
    try {
      const response = await postTeacherPasswordData(data);
      if (!response) {
        const errorMessage = {
          message: "Sudent user email ID not found",
          success: false,
        };
        dispatch(
          OTPFailure({ responseStatus: "failure", error: errorMessage })
        );
        return errorMessage;
      } else {
        dispatch(OTPSuccess({ responseStatus: "success" }));
        return response;
      }
    } catch (error) {
      console.error("OTP creation error:", error);
      const errorMessage = "OTP creation failed";
      dispatch(OTPFailure({ responseStatus: "failure", error: errorMessage }));
      return errorMessage;
    }
  };
