import { useEffect, useState } from "react";
import Calendar from "react-calendar";
import { Cookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { Row, Col } from 'antd';
import nodata from "../../../assets/images/no-data.png";
import PieChart from "../../../components/TuorPanel/Piechart";
import CustomPreloader from "../../../components/TuorPanel/Preloader";
import { GetDashboardById } from "../../../redux/action/dashBoardAction";
import "./dashboardStyle.css";
import './index.scss';

const Dashboard = () => {
  const [value, onChange] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [userID, setUserID] = useState();
  const [data, setData] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const today = new Date();
  const cookies = new Cookies();
  const dispatch = useDispatch();

  const initialUserData = () => {
    const localData = cookies.get("TeacherloggedUser");
    setUserID(localData?.id);
    dispatch(GetDashboardById(localData?.id)).then((result) => {      
      const transformedData = {
        ...result?.records,
        login_sources: [{
          tuition_mitra: result?.records?.payment_method[0]?.tuition_mitra || 0,
          other: result?.records?.payment_method[0]?.other || 0
        }]
      };
      setData(transformedData);
      setLoading(false);
    });
  };

  const formatDateToYYYYMMDD = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleDateChange = (newDate) => {
    const localData = cookies.get("TeacherloggedUser");
    onChange(newDate.toLocaleDateString());
    setUserID(localData?.id);
    setLoading(true);
    dispatch(GetDashboardById(localData?.id, formatDateToYYYYMMDD(newDate)))
      .then((result) => {
        const transformedData = {
          ...result?.records,
          login_sources: [{
            tuition_mitra: result?.records?.payment_method[0]?.tuition_mitra || 0,
            other: result?.records?.payment_method[0]?.other || 0
          }]
        };
        setData(transformedData);
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    initialUserData();
  }, []);
  
  return (
    <div className="teacher-dashboard">
      <h5 className="teacher-dashboard-overview">Overview</h5>
      <div className="teacher-dashboard-chart">
        {data ? (
          <Row gutter={[16, 16]} className="teacher-dashboard-chart-valid-data">
            <Col xs={24} sm={24} md={8} lg={8}>
              <div style={{ background: '#fff', padding: '20px', borderRadius: '8px', height: '100%' }}>
                <PieChart
                  data={Object.keys(data?.student_level_data?.[0]).length === 0
                    ? { "student level data": 0 }
                    : data?.student_level_data?.[0]
                  }
                  title="Student Level Data"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <div style={{ background: '#fff', padding: '20px', borderRadius: '8px', height: '100%' }}>
                <PieChart
                  data={data?.fee_status[0]}
                  title="Fee Status"
                />
              </div>
            </Col>
            <Col xs={24} sm={24} md={8} lg={8}>
              <div style={{ background: '#fff', padding: '20px', borderRadius: '8px', height: '100%' }}>
                <PieChart
                  data={data?.login_sources[0]}
                  title="Login Sources"
                />
              </div>
            </Col>
          </Row>
        ) : (
          <div className="teacher-dashboard-chart-container-notvalid">
            <img src={nodata} alt="no-data" />
          </div>
        )}
      </div>

      <div className="teacher-dashboard-scheduler-time">
        <Row gutter={[16, 16]} className="teacher-dashboard-scheduler-time-container">
          <Col xs={24} sm={24} md={24} lg={15} className="teacher-dashboard-scheduler-time-container-scheduler">
            <h5 className="teacher-dashboard-scheduler-time-container-scheduler-text">My Schedule</h5>
            <div className="teacher-dashboard-scheduler-time-container-card">
              <p className="mx-2">Today - {Date().slice(0, 16)}</p>
              {loading ? (
                <div className="teacher-dashboard-scheduler-time-container-card-loader">
                  <CustomPreloader />
                </div>
              ) : (
                <div className="teacher-dashboard-scheduler-time-container-card-container">
                  {data?.my_schedule?.length === 0 ? (
                    <div className="teacher-dashboard-scheduler-time-container-card-container-img">
                      <img src={nodata} alt="no-data" />
                    </div>
                  ) : (
                    <>
                      {data && data?.my_schedule?.map((item, index) => (
                        <Row gutter={[8, 8]} key={index} className="teacher-dashboard-scheduler-time-container-card-container-timing">
                          <Col xs={24} sm={24} md={24} lg={12} className="d-flex justify-content-center">
                            <p className="mb-0 text-align-center">{item.timing}</p>
                          </Col>
                          <Col xs={24} sm={24} md={24} lg={12} className="d-flex justify-content-center">
                            <p className="prime-text fw-bold mb-0 text-align-center">
                              {item.subject}
                            </p>
                          </Col>
                        </Row>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={9} className="teacher-dashboard-time-table">
            <h5 className="teacher-dashboard-time-table-calender">My Calendar</h5>
            <div className="teacher-dashboard-time-table-card">
              <Calendar
                onChange={handleDateChange}
                value={value}
                maxDate={today}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;